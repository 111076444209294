import { FC, useMemo } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import ActionPreview from '../../ActionPreview';
import { ActionPreviewBaseProps } from '../ActionBaseProps';
import { PhoneNumberResponse } from './PhoneNumberAction';

const PhoneNumberPreview: FC<ActionPreviewBaseProps<PhoneNumberResponse>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);
  const answerText = useMemo(() => (response && formatPhoneNumberIntl(response?.phoneNumber)) || '-', [response]);

  if (answerOnly) {
    return <>{answerText}</>;
  }

  return <ActionPreview data-cy="phone-number-preview" question={title} description={previewDescription} answer={answerText} />;
};

export default PhoneNumberPreview;
